<template>
  <n-config-provider :theme="darkTheme">
    <router-view/>
  </n-config-provider>
</template>
<script>
import {defineComponent} from 'vue'
import {darkTheme, NConfigProvider} from 'naive-ui'

export default defineComponent({
  components: {NConfigProvider},
  setup() {
    return {
      darkTheme
    }
  }
})
</script>
<style>
body {
  background-color: #101014;
}
</style>
