<script setup>

import axios from 'axios'
import {ref} from "vue";
import {useRoute} from "vue-router";
import {NLayout, NLayoutContent, NLayoutHeader} from "naive-ui";


let privacyText = ref('');

const route = useRoute()

console.log(route.params.app)

axios.get('../' + route.params.app + '/privacy.txt')
    .then(res => {
      console.log(res)
      privacyText.value = res.data;
    })


</script>

<template>
  <n-layout :native-scrollbar="true">
    <n-layout-header class="header">
      <div class="title">
        隐私政策
      </div>
    </n-layout-header>
    <n-layout-content>
      <div class="privacy">
        {{ privacyText }}
      </div>
    </n-layout-content>
  </n-layout>
</template>

<style>

.header {
  padding: 24px;
  font-size: 18px;
  font-weight: bold;
}

.title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.privacy {
  white-space: pre-line;
  padding: 24px 120px;
}
</style>
